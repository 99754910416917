import React, { useEffect, useState } from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import Button from "@components/elements/button"
import styled, { useTheme } from "styled-components"
import Anchor from "@components/elements/anchor"
import theme from "../styles/theme"
import media from "../styles/media"
import { KlipsEOPCta } from "@components/cta/eop/klips"
import { Accordion } from "@components/common/accordion"
import List from "@components/elements/list"
import YouTubeEmbed from "@components/directus/youtube-embed"
import { useGradient } from "@hooks/use-gradient"
import { HighGradient } from "@components/common/gradient"

const ImageWrapper = styled.div`
  text-align: center;
  .gatsby-image-wrapper {
    border-radius: 0.5rem;
    box-shadow: ${theme.shadow.default};
  }

  ${props =>
    props.reverse &&
    media.md`
      order: 1;
    `}
`

ImageWrapper.propTypes = {
  reverse: PropTypes.bool,
}

const flexGap = { gap: "12rem", gapMd: "8rem", gapSm: "6rem" }

const PartnerResources = () => {
  const { color } = useTheme()

  const [openLesson, setOpenLesson] = useState(null)
  const { gradient } = useGradient(true)

  useEffect(() => {
    const hash = window.location.hash
    if (hash === "#lesson1") {
      setOpenLesson(1)
    } else if (hash === "#lesson2") {
      setOpenLesson(2)
    } else if (hash === "#lesson3") {
      setOpenLesson(3)
    }
  }, [])

  return (
    <Layout
      fullWidth
      title={`Partner Resources`}
      description={`Klipfolio is the top rated cloud app for building dashboards and reports for your team or clients.`}
      marginless
    >
      <Flex
        {...flexGap}
        padding="10rem 0 8rem"
        margin="0 0 6rem"
        background={color.blue700}
      >
        <HighGradient src={gradient.cdn} loading="lazy" />
        <Grid position="relative" container columns="1fr 1fr" columnsMd="1fr" gap="4rem">
          <Flex gap="1rem">
            <Heading color="white">Klipfolio for Partners</Heading>
            <Paragraph
              color="white"
              fontSize="1.3rem"
              lineHeight="140%"
              fontWeight={600}
              margin="0 0 1rem"
            >
              We have created this series of onboarding modules to help ease
              your transition to Klipfolio. These lessons are structured to give
              you clear insight into the Partner Program and to help you
              familiarize yourself with the fundamental features of your
              account. We hope to lay a solid foundation for your Klipfolio
              journey.
            </Paragraph>
            <Button.KlipsSimple
              background="white"
              color="#013950"
              text="#013950"
              noticeColor="white"
              lineHeight="1"
              width="max-content"
              padding="1rem 2.75rem"
            >
              Get Klips Free
            </Button.KlipsSimple>
          </Flex>
          <YouTubeEmbed
            style={{ borderRadius: "1rem", margin: "0" }}
            id="6e6Kbv0yJrY"
          />
        </Grid>
      </Flex>
      <Flex {...flexGap} margin="0 0 8rem">
        <Container text>
          <Flex gap="1rem">
            <Heading as="h2">Topics we will cover:</Heading>
            <List as="ol" gap="0" margin="0 0 1rem 2rem">
              <li>Client Management</li>
              <li>Dashboard Management</li>
              <li>White Labelling and Customization</li>
            </List>
            <Heading as="h4">Additional Resources</Heading>
            <Paragraph margin="0 0 1rem">
              If you need additional assistance, keep the tools below in your
              back pocket as you navigate Klipfolio. Or, as always, don’t be shy
              to drop us a line. We’re always available to help!
            </Paragraph>
            <Heading as="h4">Klipfolio Help Center</Heading>
            <Paragraph margin="0 0 2rem">
              Use the&nbsp;
              <Anchor link="https://support.klipfolio.com/hc/en-us">
                Klipfolio Help Center
              </Anchor>
              &nbsp;to interact with the Klipfolio community, search
              documentation, watch helpful how-to videos, or submit support
              requests.
            </Paragraph>
            <Heading as="h3">
              While you’re here and before you get started, you should know
              about our <Anchor>Partner Directory</Anchor>.
            </Heading>
            <Paragraph>
              Becoming Klipfolio Certified will validate your dashboard building
              and reporting expertise, identify gaps in your Klipfolio
              knowledge, and help you stand out amongst the other Klipfolio
              Partners. As a Klipfolio Certified Partner you demonstrate that
              you have the highest level of knowledge around planning,
              deploying, and managing Klipfolio dashboards and reports.
            </Paragraph>
            <Paragraph>
              Upon passing the exam, your company will be added to the Certified
              Partners section of the Partner Directory, exposing your business
              to our thousands of Klipfolio clients and web visitors. You will
              also be given a Klipfolio Certified Partner badge to feature on
              LinkedIn and your website; an added bonus to showcase that you are
              steps ahead of your competitors.
            </Paragraph>
            <Paragraph>
              Last but not least, we want to invite you to be a part of our
              Slack community for Partners. Join the ‘Klipfolio Data Hive’ to
              gain access to hot leads direct from our sales team and
              peer-to-peer support. You can also offer feedback to our product
              team, get updated on the latest features, and network with your
              fellow Klipfolio Partners across the world.
            </Paragraph>
            <Paragraph margin="0 0 1rem">
              Alright, now we can get started!
            </Paragraph>
            <Accordion
              id="lesson1"
              title="Lesson 1: Navigating Client Account Management"
              open={openLesson === 1}
            >
              <Div
                padding="1.5rem"
                background={color.blueLight}
                borderRadius="1rem"
                margin="0 0 1rem"
              >
                <YouTubeEmbed
                  style={{ borderRadius: "1rem", margin: "0" }}
                  id="uAzH3zh31bA"
                />
              </Div>
              <Heading as="h3">
                One of the biggest differentiators between Klipfolio and
                Klipfolio for Partners is the ability to create and manage
                client accounts.
              </Heading>
              <Paragraph>
                The video lesson above walks you through the benefits of this
                feature and how to navigate it.
              </Paragraph>
              <List as="ol" gap="0.5rem" margin="0 0 0 2rem">
                <li>
                  <Paragraph>
                    To add a client, start by clicking the <i>Clients</i> tab on
                    the left hand navigation pane
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    To add your first client, select the orange&nbsp;
                    <i>Add a Client</i> button on the top of the page
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    From here, you can fill out all of your client’s
                    information, including their company name, company
                    description, account status, and billing options
                  </Paragraph>
                </li>
                <Paragraph margin="1rem 0 0" fontWeight={700}>
                  Account Status
                </Paragraph>
                <List>
                  <li>
                    <Paragraph fontWeight={600}>Set-up Mode</Paragraph>
                    <Paragraph>
                      Select this mode while you are in the process of building
                      dashboards and not ready to reveal them to your clients
                      just yet.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph fontWeight={600}>Trial</Paragraph>
                    <Paragraph>
                      By choosing <i>Trial</i> you act as Klipfolio to give your
                      clients the ability to test and play around in the
                      application for themselves before deciding to use it. You
                      have the ability to select a trial that lasts up to 14
                      days.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph fontWeight={600}>Active</Paragraph>
                    <Paragraph>
                      By sending your client account into an <i>Active</i>{" "}
                      state, your client can navigate their dashboards and
                      monitor data in real time. Keep in mind that once a client
                      is in <i>Active</i>
                      &nbsp;mode, you cannot revert them back to <i>
                        Trial
                      </i> or <i>Setup</i> status. <i>Active</i> mode also
                      requires you to modify the number of resources and
                      features you want to grant. More on this later.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph fontWeight={600}>Disabled</Paragraph>
                    <Paragraph>
                      By selecting <i>Disabled</i>, you can either pause a
                      client account temporarily or delete it permanently.
                    </Paragraph>
                  </li>
                </List>
                <Paragraph fontWeight={700}>Billing Status</Paragraph>
                <List>
                  <li>
                    <Paragraph fontWeight={600}>
                      Managing billing yourself
                    </Paragraph>
                    <Paragraph>
                      When you choose to manage billing by yourself, you can
                      price Klipfolio to your liking and can charge as much or
                      as little as you want. Many of our Partners choose to use
                      Klipfolio as a value add for their clients and integrate
                      Klipfolio into their existing business models.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph fontWeight={600}>
                      Let Klipfolio manage your billing
                    </Paragraph>
                    <Paragraph>
                      If you choose to for Klipfolio to manage client billing,
                      your clients’ accounts will switch to trial accounts and
                      upon logging in, they will be prompted to select one of
                      Klipfolio’s&nbsp;
                      <Anchor link="/pricing">pricing plans</Anchor>.
                    </Paragraph>
                  </li>
                </List>
                <Paragraph fontWeight={700}>Define Resources</Paragraph>
                <Paragraph>
                  Setting your clients to an <i>Active</i> state requires you to
                  assign resources to them.
                </Paragraph>
                <Paragraph>
                  <i>
                    Keep in mind: the resources you allocate to your clients
                    comes from the plan in your Parent. account. It is important
                    to familiarize yourself with your limits.
                  </i>
                </Paragraph>
                <List margin="0 0 1rem 2rem">
                  <li>
                    <Paragraph fontWeight={600}>Dashboards</Paragraph>
                    <Paragraph>
                      How many dashboards you can set up for your client.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph fontWeight={600}>Users</Paragraph>
                    <Paragraph>
                      How many named users can be added to the client.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph fontWeight={600}>API Calls</Paragraph>
                    <Paragraph>
                      Refer only to calls made to the&nbsp;
                      <Anchor link="https://apidocs.klipfolio.com/reference/getting-started">
                        Klipfolio API
                      </Anchor>
                      . It is NOT required for client data sources to update and
                      is therefore typically be set to 0.
                    </Paragraph>
                  </li>
                </List>
                <Paragraph>
                  At this time you also get to define the features you will be
                  using for your client. Select any combination of: download
                  reports, email reports, embedded links, private or public
                  links, and SSO.
                </Paragraph>
                <li>
                  <i>Save</i>; your client account has now been created. By
                  clicking on the sign in as <i>Admin</i> tab beside the client
                  you created, you can login and manage their Klipfolio account
                  as you would your own (i.e. build dashboards, generate
                  reports, etc.)
                </li>
              </List>
              <Paragraph>
                Managing clients in this way will not only allow you to keep
                everything organized it also gives you the best foundation on
                which to build. In the next lesson we will share the best
                practices for managing dashboards for your clients.
              </Paragraph>
            </Accordion>
            <Accordion
              id="lesson2"
              title="Lesson 2: Dashboard Management"
              open={openLesson === 2}
            >
              <Div
                padding="1.5rem"
                background={color.blueLight}
                borderRadius="1rem"
                margin="0 0 1rem"
              >
                <YouTubeEmbed
                  style={{ borderRadius: "1rem", margin: "0" }}
                  id="MqiNCQjIs5I"
                />
              </Div>
              <Paragraph margin="0 0 1rem">
                This session dives into the foundation of Klipfolio Partner
                accounts: building and monitoring dashboards for your clients.
                We will show you two of the main ways you can create and share
                dashboards and some of the best practices associated with these
                ways.
              </Paragraph>
              <Heading as="h2">Use Instant Klips and Dashboards</Heading>
              <Paragraph>
                Want to get something set up quickly or while you learn how to
                build your own?
              </Paragraph>
              <List as="ol">
                <li>
                  Go to clients and click Admin to the right of the client you
                  want to build for
                </li>
                <li>
                  Click the “+” to the right of Active Dashboards, this will
                  open a blue panel
                </li>
                <li>Want to add a blank Dashboard?</li>
                <ul style={{ margin: "0 0 0 2rem" }}>
                  <li>Click the orange Add a Blank Dashboard button</li>
                  <li>Click Add a Klip at the top of your Dashboard</li>
                  <li>Select Klip Gallery and add the ones you want</li>
                </ul>
                <li>Want to add a full Dashboard?</li>
                <ul style={{ margin: "0 0 0 2rem" }}>
                  <li>
                    On the right hand side, under Dashboard Gallery, click More
                  </li>
                  <li>
                    Browse and once you’ve found one you like, click Get This
                    Dashboard
                  </li>
                </ul>
              </List>
              <Heading as="h2">
                How to create unique dashboards for your clients
              </Heading>
              <List as="ol">
                <li>
                  Go to clients and click <i>Admin</i> to the right of the
                  client you want to build for
                </li>
                <li>
                  Click <i>Data Sources</i> in left Nav
                </li>
                <li>
                  Click <i>Create a New Data Source</i>
                </li>
                <li>
                  Select a service from the list or if it isn’t listed try one
                  of the core connectors to set up your data source.
                </li>
                <li>
                  Model the data or click <i>New Klip</i> to start building
                </li>
                <li>
                  Add the <i>Klip</i> to your <i>Dashboard</i> and repeat steps
                  2-6 as required
                </li>
              </List>
              <Heading as="h2">
                How to create a Dashboard template in your Parent account that
                you can import into Client accounts
              </Heading>
              <Paragraph>
                One of the most common use cases we see is agencies and
                consultants who want to save time by building dashboards to use
                as templates for multiple clients. These templates can be
                imported to each of your&nbsp;
                <i>Client</i> accounts and then, if required, linked to their
                individualized data. Importing a&nbsp;
                <i>Dashboard</i> maintains the layout, creates copies of each{" "}
                <i>Klip</i> and the <i>Data Sources</i>
                &nbsp;and gives you the option to update copied Klips in all
                clients from the Parent.
              </Paragraph>
              <Paragraph>
                <i>
                  Note: It is recommended that dashboard templates be custom
                  built. While the pre-built gallery Klips can be imported,{" "}
                  <b>do not</b> connect them prior to importing to clients.
                </i>
              </Paragraph>
              <Paragraph>
                <i>
                  Advanced: When you have access to data for multiple clients
                  with a single login, such as for example Google Analytics MCC,
                  Facebook or SQL you can create&nbsp;
                  <Anchor link="https://support.klipfolio.com/hc/en-us/articles/216183237-BETA-Working-with-dynamic-data-sources">
                    Dynamic Data Sources
                  </Anchor>
                  . This sort of data source uses the value for a specific
                  client as a variable and can therefore leverage&nbsp;
                  <Anchor link="https://support.klipfolio.com/hc/en-us/articles/223222128-Company-Properties">
                    Company Properties
                  </Anchor>
                  &nbsp;to simplify import.
                </i>
              </Paragraph>
              <List as="ol">
                <li>
                  <Paragraph>
                    Sign into <i>Parent</i> account
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Create the dashboard you want to import into your clients.
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Select <i>Clients</i> from the side navigation
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Sign in as an <i>Admin</i> to the client account to which
                    you wish to add the dashboard
                  </Paragraph>
                  <i>Note: This is a required step.</i>
                </li>
                <li>
                  <Paragraph>
                    Click <i>Dashboards</i> in the side navigation then click
                    the blue Import button on the top right corner of the page
                  </Paragraph>
                </li>
                <ul style={{ margin: "0 0 0 2rem" }}>
                  <li>
                    <Paragraph>
                      Note: These same steps apply for importing <i>Klips</i>{" "}
                      and
                      <i>Data Sources</i> as well
                    </Paragraph>
                  </li>
                </ul>
                <li>
                  <Paragraph>
                    In the first dropdown select your <i>Parent account</i>.
                    Then select the
                    <i>Dashboard(s)</i> you wish to import
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Click <i>Next</i> to be given a summary of the{" "}
                    <i>Dashboard</i> and its associated <i>Klips</i>
                    &nbsp;and <i>Data Sources</i> that are being imported
                  </Paragraph>
                </li>

                <li>
                  <Paragraph>
                    Click <i>Import Now</i>
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Click the <i>Dashboard</i> you just imported
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Select <i>Open Dashboard</i>
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Don’t forget to&nbsp;
                    <i>
                      <a href="https://support.klipfolio.com/hc/en-us/articles/215548648-Sharing-Klip-dashboards">
                        share
                      </a>
                    </i>
                    &nbsp;
                    <i>Dashboards</i> with the users in the <i>Client</i>{" "}
                    account!
                  </Paragraph>
                </li>
              </List>
              <Heading as="h3">
                Update Imported Klips from your Parent Account
              </Heading>
              <Paragraph>
                Importing not only speeds up rolling out your custom dashboards
                to clients it also gives you the option to update to update all
                copies of the <i>Klips</i> at once! To make changes to imported
                Klips you make the change to the original in your Parent account
                and push these to the copies in your <i>Client</i>
                &nbsp;accounts.
              </Paragraph>
              <List as="ol" margin="0 0 0 2rem">
                <li>
                  <Paragraph>
                    In <i>Parent</i> account select <i>Klips</i> from side nav
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Search for the <i>Klip</i> that you would like to update.
                    You will notice a button labelled&nbsp;
                    <i>Client Instances</i>. You can click here to reveal how
                    many and to which <i>Clients</i> you have imported this{" "}
                    <i>Klip</i>.
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Edit the <i>Klip</i> to apply the changes.
                  </Paragraph>
                </li>
                <ul style={{ margin: "0 0 0 2rem" }}>
                  <li>
                    <Paragraph>
                      Note: If you need to modify the data source to include
                      additional data you will need to re-import as updating
                      instances only applies to <i>Klips</i>.
                    </Paragraph>
                  </li>
                </ul>
                <li>
                  Click <i>Update Client Instances</i> to push all changes you
                  made to the Klip to all instances in your&nbsp;
                  <i>Client Accounts</i>.
                </li>
              </List>
              <Paragraph>
                Operating from <i>Parent</i> to <i>Client</i> in this manner is
                a recommended best practice but does not preclude you from
                making changes to <i>Klips</i> directly in the <i>Client’s</i>
                &nbsp;account. Keep in mind that updating instances affects all
                copies and therefore to create a unique version for a specific
                client it is recommended to either first duplicate the imported
                Klip and then make all modifications to the copy or if for
                multiple clients create in Parent and import.
              </Paragraph>
              <Paragraph>
                Need help? <Anchor link="/services">Klipfolio Services</Anchor>
                &nbsp;can be added to any subscription, giving you access to a
                dedicated Klip expert who can help answer complex questions
                throughout your Klipfolio journey.
              </Paragraph>
            </Accordion>
            <Accordion
              id="lesson3"
              title="Lesson 3: White Labelling and Customization"
              open={openLesson === 3}
            >
              <Div
                padding="1.5rem"
                background={color.blueLight}
                borderRadius="1rem"
                margin="0 0 1rem"
              >
                <YouTubeEmbed
                  style={{ borderRadius: "1rem", margin: "0" }}
                  id="6e6Kbv0yJrY"
                />
              </Div>
              <Paragraph margin="0 0 1rem">
                In this lesson, we explain the different ways you can customize
                your Klipfolio account. We understand that maintaining brand
                consistency is important. Customization allows you to apply your
                branding and remove Klipfolio branding altogether, meaning you
                have the ability to create a unique experience for each of your
                clients. Note:&nbsp;
                <Anchor link="https://support.klipfolio.com/hc/en-us/articles/216183707-White-Label-Options">
                  White Label
                </Anchor>
                &nbsp;is a paid add on.
              </Paragraph>
              <Heading as="h2">How to White Label a Klipfolio account</Heading>
              <List as="ol" margin="0 0 0 2rem">
                <li>
                  <Paragraph>
                    Sign into your <i>Parent Account</i>.
                  </Paragraph>
                </li>
                <ul style={{ margin: "0 0 0 2rem" }}>
                  <li>
                    <Paragraph>
                      Note: Your white label settings will apply to all clients
                      except those that you set up to be billed by Klipfolio.
                    </Paragraph>
                  </li>
                </ul>
                <li>
                  <Paragraph>
                    Click <i>Account</i> from the top navigation pane
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Click <i>Settings</i>
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Click <i>White Label</i> from the side navigation
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Once in the <i>White Label</i> window you will be able to
                    make changes to customize every aspect of your Klipfolio
                    account:
                  </Paragraph>
                </li>
                <ul>
                  <li>
                    <Paragraph>Product Name</Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Rename the terms <i>Klips</i> and <i>Dashboards</i>
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Links and Footer (help, legal, <i>powered by</i>, and hide
                      the copyright notice)
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Support email (After Domain Alias and Email Redirection is
                      completed replace the from address in emails sent out by
                      the application)
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Embeds (change the call-to-action text)
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>Sign in logo</Paragraph>
                  </li>
                  <li>
                    <Paragraph>Footer logo</Paragraph>
                  </li>
                  <li>
                    <Paragraph>Email logo</Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      Favicon (replace the icon associated with your brand that
                      appears in the user’s device home screen)
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>Home screen icon</Paragraph>
                  </li>
                </ul>
              </List>
              <Heading as="h2">
                How to override Klipfolio branding using CSS
              </Heading>
              <List as="ol" margin="0 0 0 2rem">
                <li>
                  Click <i>Branding</i> from the side navigation
                </li>
                <li>
                  Click <i>Edit</i> and you will be shown two options:
                </li>
                <ul style={{ margin: "0 0 0 2rem" }}>
                  <li>
                    <Paragraph fontWeight={600}>Default</Paragraph>
                    <Paragraph>
                      Select light or dark standard Klipfolio themes
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph fontWeight={600}>Custom</Paragraph>
                    <Paragraph>
                      You have the ability to change many facets of the
                      Klipfolio app including:
                    </Paragraph>
                  </li>
                  <ul style={{ margin: "0 0 0 2rem" }}>
                    <li>
                      <Paragraph>
                        Icons for both the web and mobile applications
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph>
                        Click base theme to select light or dark
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph>
                        Dashboard CSS allows you to fully customize and override
                        the existing dashboard style
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph>
                        Application CSS allows you to change the login screen
                        and all other components of the Klipfolio app
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph>
                        Update the dashboard script or application script to
                        make things more dynamic
                      </Paragraph>
                    </li>
                  </ul>
                </ul>
              </List>
              <Paragraph>
                <i>
                  Note: Clients with the CSS feature enabled will, by default,
                  inherit the theme from the Parent. This can however be
                  modified by client to create unique custom themes for each.
                </i>
              </Paragraph>
              <Paragraph margin="0 0 2rem">
                Want to learn more about customizing your dashboards?&nbsp;
                <Anchor link="/klips/custom-theme">Click here</Anchor> to view
                our custom theme power-up and explore our pre-built starter
                packs.
              </Paragraph>
              <Heading as="h2">How to set up a custom Domain</Heading>
              <Paragraph>
                <Anchor link="https://support.klipfolio.com/hc/en-us/articles/215548678-Domain-Alias-Requirements">
                  Domain Alias
                </Anchor>
                &nbsp;allows you to create a unique Dashboards.CompanyName.com
                URL where users can login or for use with Published Links.
                Setting up a Domain takes 3-5 business days and is a
                prerequisite to configuring Email and Oauth Redirection.
              </Paragraph>
              <Paragraph>
                Once purchased, either stand alone or as part of White Label,
                there is a form to fill in which when submitted will
                automatically create a support ticket and start the process.
              </Paragraph>
            </Accordion>
          </Flex>
        </Container>
      </Flex>
      <KlipsEOPCta />
    </Layout>
  )
}

export default PartnerResources
